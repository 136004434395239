<template>
  <FormSection>
    <form class="form" name="contact" @submit.prevent="submit">
      <input type="hidden" name="form-name" value="contact"/>
      <div class="input-container input">
        <input type="text" id="name" required v-model="name"/>
        <label for="name">Name</label>
      </div>
      <div class="input-container input">
        <input type="email" id="email" required v-model="email"/>
        <label for="email">Email</label>
      </div>
      <div class="input-container input">
        <input type="text" id="subject" required v-model="subject"/>
        <label for="subject">Subject</label>
      </div>
      <div class="input-container area">
        <textarea type="text" id="message" required v-model="message"/>
        <label for="message">Message</label>
      </div>
      <button class="button">SEND</button>
    </form>
  </FormSection>
</template>
<script setup lang="ts">

const name = ref("");
const email = ref("");
const subject = ref("");
const message = ref("");

// on submit, call the server api: contact.post.ts, with name, email, message :
const submit = async () => {
  const {data, error} = await useFetch("/api/contact", {
    method: "POST",
    body: JSON.stringify({
      name: name.value,
      email: email.value,
      message: message.value,
      subject: subject.value,
    }),
  });

  if (error.value) {
    alert(`Error sending message: ${JSON.stringify(error.value)}`);
  } else {
    alert(`Your message has been sent. We will get back to you shortly at ${email.value}.`);
    navigateTo("/");
  }
};

</script>
